_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\uc774\uc6a9 \uac00\ub2a5\ud55c \uac1c\uc694 \uc5c6\uc74c. \ub808\ucf54\ub4dc\ub97c \ud074\ub9ad\ud558\uc5ec \ub354 \uc790\uc138\ud55c \ub0b4\uc6a9\uc744 \ubcf4\uc2ed\uc2dc\uc624",
"crm.appointments.status.markcompleted1": "\uc644\ub8cc\ub85c \ud45c\uc2dc",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">\uc774 \ubcf4\uae30\uc758 \ubaa8\ub4e0 </span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'>{0}</span><span class=\"dIB vaM\">{1}\uc744(\ub97c) \uc120\ud0dd\ud588\uc2b5\ub2c8\ub2e4.</span>",
"crm.module.selectall": "\uc774 \ubcf4\uae30\uc758 \ubaa8\ub4e0 {0} \uc120\ud0dd",
"records": "\uae30\ub85d",
"crm.record.not.accessible": "\ub808\ucf54\ub4dc\ub97c \uc0ac\uc6a9\ud560 \uc218 \uc5c6\uc74c",
"crm.view.calls.recording.err.msg": "\uc74c\uc131 \ub179\uc74c\uc5d0 \uc7ac\uc0dd \uac00\ub2a5\ud55c \uc624\ub514\uc624 URL\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1}\uc774(\uac00) \uc120\ud0dd\ub418\uc5c8\uc2b5\ub2c8\ub2e4.</span>",
"totalrecords": "\ucd1d \ub808\ucf54\ub4dc",
"crm.listview.options.text.clip": "\ud14d\uc2a4\ud2b8 \uc790\ub974\uae30",
"crm.listview.options.text.wrap": "\ud14d\uc2a4\ud2b8 \uc904 \ubc14\uafc8",
"crm.listview.options.reset.width": "\uc5f4 \ud06c\uae30 \ub2e4\uc2dc \uc124\uc815",
"crm.listview.options.manage": "\uc5f4 \uad00\ub9ac",
"crm.listview.customview.locked": "\uc774 \ubcf4\uae30\uac00 \uc7a0\uaca8 \uc788\uc5b4 \uc5f4\uc744 \uc0ac\uc6a9\uc790 \uc9c0\uc815\ud560 \uc218 \uc5c6\uc74c",
"crm.api.apidashboard.SortBy": "\uc815\ub82c \uae30\uc900",
"crm.privacy.listview.consent.locked": "\uae30\ub85d\uc774 \ucc98\ub9ac\ud560 \uc218 \uc5c6\uac8c \uc7a0\uaca8 \uc788\uc2b5\ub2c8\ub2e4",
"crm.label.reschedule.call": "{0} \uc608\uc57d \uc870\uc815",
"crm.button.edit": "\ud3b8\uc9d1",
"Delete": "\uc0ad\uc81c",
"crm.appointments.status.markcancel": "{0} \ucde8\uc18c",
"crm.appointments.status.markreschedule": "{0} \uc608\uc57d \uc870\uc815",
"crm.label.cancel.call": "{0} \ucde8\uc18c",
"crm.label.mark.completed": "\uc644\ub8cc\ub85c \ud45c\uc2dc",
"crm.listview.customview.locked": "\uc774 \ubcf4\uae30\uac00 \uc7a0\uaca8 \uc788\uc5b4 \uc5f4\uc744 \uc0ac\uc6a9\uc790 \uc9c0\uc815\ud560 \uc218 \uc5c6\uc74c",
"pf.deleted.state": "\uc0ad\uc81c\ub41c \uc0c1\ud0dc",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "{0}\uc5d0 \ub3d9\uae30\ud654",
"crm.schedules.prefix.in": "-",
"crm.record.record.locked.other": "\ub2e4\ub978 \ud504\ub85c\ud544 \uc0ac\uc6a9\uc790\uc5d0 \ub300\ud574 \ub808\ucf54\ub4dc\uac00 \uc7a0\uacbc\uc2b5\ub2c8\ub2e4.",
"crm.record.lock.record.locked": "\ub808\ucf54\ub4dc\uac00 \uc7a0\uacbc\uc2b5\ub2c8\ub2e4.",
"crm.approvalProcess.label.waitingForApproval": "\uc2b9\uc778 \ub300\uae30 \uc911",
"crm.reviewprocess.record.review.pending": "\uac80\ud1a0 \ub300\uae30 \uc911",
"crm.approvalProcess.label.waitingForFindAndMerge": "\uc911\ubcf5 \ud56d\ubaa9 \ubcd1\ud569\uc774 \uc9c4\ud589 \uc911\uc785\ub2c8\ub2e4.",
"crm.privacy.listview.consent.locked": "\uae30\ub85d\uc774 \ucc98\ub9ac\ud560 \uc218 \uc5c6\uac8c \uc7a0\uaca8 \uc788\uc2b5\ub2c8\ub2e4",
"crm.zia.vision.processing": "Zia\uac00 \uc774\ubbf8\uc9c0\uc758 \uc720\ud6a8\uc131\uc744 \uac80\uc0ac\ud558\uace0 \uc788\uc2b5\ub2c8\ub2e4.",
"crm.zia.vision.rejected.msg": "\uc774\ubbf8\uc9c0 \uc720\ud6a8\uc131 \uac80\uc0ac \uc2e4\ud328\ub85c \ub808\ucf54\ub4dc\uac00 \uac70\ubd80\ub418\uc5c8\uc2b5\ub2c8\ub2e4.",
"crm.zia.vision.record.failure.msg": "\uc774\ubbf8\uc9c0 \uc720\ud6a8\uc131 \uac80\uc0ac \uc2e4\ud328\ub85c \ub808\ucf54\ub4dc\uac00 \uc2b9\uc778\uc744 \ub300\uae30\ud558\uace0 \uc788\uc2b5\ub2c8\ub2e4.",
"crm.alert.max.cvcols.reached": "\ucd5c\ub300 {0}\uac1c\uc758 \uc5f4\ub9cc \uc120\ud0dd\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",
"crm.label.small.tags": "\ud0dc\uadf8",
"crm.label.edit.module": "{0} \ud3b8\uc9d1",
"crm.social.integ.no.result": "\uacb0\uacfc \uc5c6\uc74c",
"custommodule.crmfield.fl.name": "{0} \uc774\ub984:",
"Appointment": "\uc57d\uc18d",
"crm.button.create.call": "\ud1b5\ud654 \uc0dd\uc131",
"crm.module.create": "{0} \ub9cc\ub4e4\uae30",
"crm.customview.activty.badge": "\ud65c\ub3d9 \ubc43\uc9c0",
"crm.button.callnow": "\uc9c0\uae08 \uc5f0\uacb0",
"crm.manualcalllist.complete.call": "\ud1b5\ud654 \uae30\ub85d",
"crm.label.schedule.call": "\ud1b5\ud654 \uc608\uc57d",
"crm.filter.label.replied":"\ub2f5\uc7a5\ud568",
"bytes": "\ubc14\uc774\ud2b8",
"crm.field.label.user.lookup": "\uc0ac\uc6a9\uc790",
"crm.translation.refresh.cases": "\uc218\ud589\ud558\ub824\ub294 \uc791\uc5c5\uc744 \uc644\ub8cc\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4. \ud398\uc774\uc9c0\ub97c \uc0c8\ub85c \uace0\uce58\uace0 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",
"crm.project.sync.notes.info": "\uc5f0\uacb0\ub41c Projects\uc758 \uc791\uc5c5 \uba54\ubaa8\ub9cc \ud45c\uc2dc\ub428",
"crux.note.content.limit.warning.msg":"\uba54\ubaa8 \ub0b4\uc6a9\uc5d0\ub9cc {0}\uc790\ub97c \uc800\uc7a5\ud558\ub3c4\ub85d \ud5c8\uc6a9\ud569\ub2c8\ub2e4",
"crux.note.formatting.options":"\uc11c\uc2dd \uc635\uc158",
"crm.label.required": "\ud544\uc218",
"crux.condition.not.belongs.to":"\uc740(\ub294) {0}\uc5d0 \uc18d\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",
"crm.project.sync.notes.info": "\uc5f0\uacb0\ub41c Projects\uc758 \uc791\uc5c5 \uba54\ubaa8\ub9cc \ud45c\uc2dc\ub428",
"crm.sf.permission.no.edit": "\ud56d\ubaa9\uc744 \uc5c5\ub370\uc774\ud2b8\ud560 \uad8c\ud55c\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
"crm.sf.permission.no.create": "\ud56d\ubaa9\uc744 \ucd94\uac00\ud560 \uad8c\ud55c\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
"crm.sf.permission.no.delete": "\ud56d\ubaa9\uc744 \uc0ad\uc81c\ud560 \uad8c\ud55c\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
"crux.note.this.note":"\uc774 \uba54\ubaa8",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "\uc0ac\uc6a9\uc790 \uc5ed\ud560\uc5d0 \ub85c\uadf8\uc778",
"Unit Price": "\ub2e8\uc704 \uac00\uaca9",
"Qty in Stock": "\uc7ac\uace0 \uc218\ub7c9",
"crm.label.Product Code": "\uc81c\ud488 \ucf54\ub4dc",
"TotalAfterDiscount": "\ud560\uc778 \ud6c4 \ucd1d\uc561",
"crm.lookupfilter.entity.errormsg": "\uc120\ud0dd\ud55c {0}\uc774(\uac00) \uc870\ud68c \uae30\uc900\uc744 \ub9cc\uc871\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",
"crm.iamexception.maxlen": "{1}\uc5d0 \ub300\ud574 {0}\uc790 \uc774\uc0c1\uc744 \uc785\ub825\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",
"crm.record.locking.permission.denied": "\ub808\ucf54\ub4dc\uac00 \uc7a0\uaca8 \uc788\uc73c\ubbc0\ub85c \uc774 \uc791\uc5c5\uc744 \uc218\ud589\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",
"List Price": "\uc815\uac00",
"crm.label.no.more.records": "\ub354 \uc774\uc0c1 \ub808\ucf54\ub4dc \uc5c6\uc74c",
"crm.workflow.rule.created.dateAndTime":"{0} - {1}, {2}\uc2dc",
"crux.new.note.msg":"\uc774 \uba54\ubaa8\uc758 \uc8fc\uc81c\ub294?",
"crm.image.uploaded":"\uc774\ubbf8\uc9c0 \uc5c5\ub85c\ub4dc\ub428",
"crm.imageupload.drag.drop.here":"\uc5ec\uae30\uc5d0 \uc774\ubbf8\uc9c0 \ub4dc\ub798\uadf8 \uc564 \ub4dc\ub86d\ud558\uae30",
"crm.fileupload.drag.drop.here":"\uc5ec\uae30\uc5d0 \ud30c\uc77c \ub4dc\ub798\uadf8 \uc564 \ub4dc\ub86d\ud558\uae30",
"crm.fileupload.wait.msg":"\uc120\ud0dd\ud55c \ud30c\uc77c\uc774 \uc5c5\ub85c\ub4dc\ub420 \ub54c\uae4c\uc9c0 \uae30\ub2e4\ub9ac\uc138\uc694",
"crm.fileupload.attach.fail":"\ucca8\ubd80 \ud30c\uc77c \uc2e4\ud328",
"crm.fileupload.support.format":"Zia\uc758 \ud559\uc2b5\uc744 \uc704\ud55c \ud30c\uc77c \ud615\uc2dd(jpeg, jpg, png, pdf)\uc744 \uc9c0\uc6d0\ud569\ub2c8\ub2e4",
"custmr.prtl.user.role": "\ud3ec\ud138 \uc0ac\uc6a9\uc790",
"crm.subform.empty.alert.header":"\ube44\uc5b4 \uc788\ub294 \ud589\uc740 \uc800\uc7a5\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4",
"crm.crud.lookup.module.inaccessible": "\uc774 \uc870\ud68c \ubaa8\ub4c8\uc5d0 \uc561\uc138\uc2a4\ud560 \uad8c\ud55c\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
"crm.crud.lookup.inaccessible.record": "\ub808\ucf54\ub4dc\uac00 \uc874\uc7ac\ud558\uc9c0 \uc54a\uac70\ub098 \ub610\ub294 \uc811\uadfc\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4",
"crm.custombutton.valid.weburl.check": "\uac12\uc744 \uc785\ub825\ud558\uc2ed\uc2dc\uc624.",
"crm.label.subform.goto.top": "\ubaa9\ub85d \ub9e8 \uc704\ub85c \uc774\ub3d9",
"crm.label.subform.row.show.all": "\ubaa8\ub450 \ud45c\uc2dc({0})",
"cpq.pr.nodiscount": "\uc5f0\uacb0\ub41c \ud560\uc778 \uc5c6\uc74c",
"crm.inventory.lineitem.no.pricebook.new": "\uc5f0\uacb0\ub41c {0}\uc774(\uac00) \uc5c6\uc2b5\ub2c8\ub2e4.",
"crm.label.subform.allowedlimit": "\ud5c8\uc6a9 \ud55c\ub3c4 {0} \ud589\uc5d0 \ub3c4\ub2ec\ud588\uc2b5\ub2c8\ub2e4",
"crm.reports.integ.okay": "\ud655\uc778",
"crm.crud.subform.deleted.record": "\uc5c5\ub370\uc774\ud2b8\ub41c \ud558\uc704\uc591\uc2dd \ud589\uc774 \uc774\ubbf8 \uc0ad\uc81c\ub418\uc5c8\uc2b5\ub2c8\ub2e4. \ud398\uc774\uc9c0\ub97c \uc0c8\ub85c \uace0\uce58\uace0 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",
"crux.criteria.fieldlabel.valid.check":"\uc62c\ubc14\ub978 \ud544\ub4dc \ub808\uc774\ube14\uc744 \uc785\ub825\ud558\uc2ed\uc2dc\uc624",
"crm.subform.delete.confirm.header":"하위양식 행 삭제",
"crm.subform.delete.confirm.msg": "{0}의 선택한 행을 삭제하시겠습니까?",
"crm.subform.delete.tooltip.msg" :"행을 삭제할 수 없습니다. {0}에 대해 적어도 하나 이상의 입력이 필요합니다.",
	"crm.condition.belongs.to":"{0}\uc5d0 \uc18d\ud568",//no i18n
"crm.condition.not.belongs.to":"{0}\uc5d0 \uc18d\ud558\uc9c0 \uc54a\uc74c",//no i18n
"crm.orchestration.error.selectvalue": "\uac12\uc744 \uc120\ud0dd\ud558\uc2ed\uc2dc\uc624",
	"crm.attach.upload.image":"\uc774\ubbf8\uc9c0 \ucca8\ubd80",//no i18n
"crm.general.select.photo":"\uc774\ubbf8\uc9c0 \uc120\ud0dd",//no i18n
"crm.imageupload.wait.msg":"\uc120\ud0dd\ud55c \uc774\ubbf8\uc9c0\uac00 \uc5c5\ub370\uc774\ud2b8\ub420 \ub54c\uae4c\uc9c0 \uae30\ub2e4\ub9ac\uc138\uc694.",//no i18n
"crm.image.filepicker.desc.draganddrop":"\uc774\ubbf8\uc9c0\ub97c \ub04c\uc5b4\uc11c \ub193\uc73c\uc2ed\uc2dc\uc624.",//no i18n
"crm.image.filepicker.desc.browse":"\ud074\ub9ad\ud558\uc5ec \uc774\ubbf8\uc9c0\ub97c \uac80\uc0c9\ud558\uc138\uc694...",//no i18n
"crm.attach.upload.userinfo":"\ucd1d \ud06c\uae30 <span class",//no i18n
"crm.image.supported.formats":"\uc9c0\uc6d0\ub418\ub294 \ud615\uc2dd: JPEG, PNG, GIF \ubc0f BMP.",//no i18n
"crm.imageupload.allowed.field.length":"\ucd5c\ub300 {0}\uac1c\uc758 \uc774\ubbf8\uc9c0\ub9cc \uc5c5\ub85c\ub4dc\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",//no i18n
"crm.general.crop.and.set":"\uc790\ub974\uae30 \ubc0f \uc124\uc815",//no i18n
"crm.attach.option.label":"\ucca8\ubd80",//no i18n
"crm.image.crop.and.rotate":"\uc790\ub974\uae30 \ubc0f \ud68c\uc804",//no i18n
"crm.image.resolution":"\ud574\uacb0",//no i18n
"crm.attachment.size":"\ud06c\uae30",//no i18n
"crm.label.close":"\ub2eb\uae30",//no i18n
"crm.label.previous":"\uc774\uc804",//no i18n
"crm.label.next":"\ub2e4\uc74c",//no i18n
"crm.subform.record.create.maxrow.limit": "{1}\uc5d0\uc11c \ud5c8\uc6a9\ub418\ub294 \ucd5c\ub300 {0} \uac1c \ub808\ucf54\ub4dc",
"crm.label.subform.addrows": "\ud589 \ucd94\uac00",
"crm.tax.association.check": "\uc774 \uc81c\ud488\uacfc \uc5f0\uacb0\ub41c \uc138\uae08\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
	"crm.gallery.inventory.template.discount": "\ud560\uc778",
	"crm.inventory.discount.scheme.range": "\ubc94\uc704",
	"Description": "\uc124\uba85",
	"crm.inventory.lineitem.no.pricebook": "\uc5f0\uacb0\ub41c \uac00\uaca9 \uc77c\ub78c\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",
	"crm.recordImage.previewimage":"\uc774\ubbf8\uc9c0 \ubbf8\ub9ac\ubcf4\uae30",//no i18n
"crm.FileuploadField.addNewImage":"\uc774\ubbf8\uc9c0 \uc5c5\ub85c\ub4dc",//no i18n
"crm.fileuploader.removefile":"\uc81c\uac70",//no i18n
	"voc.gc.configure":"\uad6c\uc131",//no i18n
"Edit":"\ud3b8\uc9d1",//no i18n
	"crm.criteria.secondaryfield.valid.check " : "{0}\uc5d0 \uc77c\uce58\ud558\ub294 \uac12\uc744 \uc81c\uacf5\ud558\uc2ed\uc2dc\uc624",
	"crm.label.field":"\ud544\ub4dc",//no i18n
"crm.label.value":"\uac12",//no i18n
	"crm.button.clone":"\ubcf5\uc81c",//no i18n
	"sentiment.criteria.wrongcriteria":"\uae30\uc900\uac12\uc774 {0}\ubcf4\ub2e4 \ud074 \uc218 \uc5c6\uc74c",//no i18n
"crm.mb.field.common.splc":"\ud2b9\uc218 \ubb38\uc790\ub294 \ud5c8\uc6a9\ub418\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4. \uc720\ud6a8\ud55c \uac12\uc744 \uc785\ub825\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"crm.view.attachment.download":"\ub2e4\uc6b4\ub85c\ub4dc",//no i18n
"crm.label.view":"\ubcf4\uae30",//no i18n
	"crm.label.field.plural":"\ud544\ub4dc",//no i18n
	"crm.label.in.minutes":"{0}(\ubd84)",//no i18n
	"crm.security.roles.list":"\uc5ed\ud560 \ubaa9\ub85d",//no i18n
"crm.security.roles.lookup.info":"\ubaa9\ub85d\uc5d0\uc11c \ud558\ub098 \uc774\uc0c1\uc758 \uc5ed\ud560\uc744 \uc120\ud0dd\ud558\uc2ed\uc2dc\uc624",//no i18n
"crm.territory.addterritory":"\uc9c0\uc5ed \ucd94\uac00",//no i18n
"crm.title.edit.territory":"\uc9c0\uc5ed \ud3b8\uc9d1",//no i18n
"crm.territory.title.assign.territories":"\uc9c0\uc5ed \ud560\ub2f9",//no i18n
	"crm.label.context.help":"\ub3c4\uc6c0\ub9d0",//no i18n
	"crm.label.from":"\uc2dc\uc791",//no i18n
"crm.label.to":"\uc885\ub8cc",//no i18n
	"workflow.option.webhookFailure.fromDate":"\uc2dc\uc791\uc77c",//no i18n
"workflow.option.webhookFailure.toDate":"\uc885\ub8cc\uc77c",//no i18n
"crm.custom.field.less.than.equalto":"{0}\uc740(\ub294) {1} \uc774\ud558\uc5ec\uc57c \ud569\ub2c8\ub2e4.",//no i18n
	"crm.template.listview.search.no.results":"\uacb0\uacfc \uc5c6\uc74c",//No I18n
	"crm.label.tag.new":"\uc0c8 \ud0dc\uadf8",//No I18n
	"crm.label.enter.tag":"\ud0dc\uadf8 \uc785\ub825",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"\uc2dc\uc791\uc77c\uc740 \uc885\ub8cc\uc77c \uc774\ud6c4\uac00 \ub420 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crux.comboBox.max.limit":"10\uac1c\uac00 \ub118\ub294 {0} {1}\uc740(\ub294) \uc120\ud0dd\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//NO I18n
	"Administrator":"\uad00\ub9ac\uc790",//No I18n
	"Standard":"\ud45c\uc900",//No I18n
	"crm.button.add":"\ucd94\uac00",//NO I18n
	"crm.label.users":"\uc0ac\uc6a9\uc790", //NO I18n
  "crm.workflow.alert.roles":"\uc5ed\ud560", //NO I18n
  "crm.security.groups":"\uadf8\ub8f9", //NO I18n
	"crm.label.available" : "\uc0ac\uc6a9 \uac00\ub2a5", //NO I18n
	"crm.label.assign.manually" : "\ud560\ub2f9", //NO I18n
	"crm.globalsearch.option.all": "\uc804\uccb4", //NO I18n
	"webform.status.Active":"\ud65c\uc131", //NO I18n
	"Inactive":"\ube44\ud65c\uc131", //NO I18n
  "Confirmed":"\ud655\uc778", //NO I18n
  "crm.user.component.unconfirmed":"\ud655\uc778 \uc548 \ub428",//no i18n
  "DeletedUser":"\uc0ad\uc81c\ub428", //NO I18n
  "crm.feed.group.admin":"\uad00\ub9ac\uc790", //NO I18n
  "crm.ln.lable.current":"\ud604\uc7ac", //NO I18n
	"crm.label.selected": "\uc120\ud0dd\ub428",//NO I18n
	"crm.auditlog.user": "\uc9c1\uc6d0", //NO I18n
	"cob.role": "\uc5ed\ud560", //NO I18n
	"zoho.email": "\uc774\uba54\uc77c", //NO I18n
	"Profile": "\ud504\ub85c\ud544", //NO I18n
	"crm.security.group.users.empty": "\uc0ac\uc6a9\uc790\uac00 \uc5c6\uc2b5\ub2c8\ub2e4.", //NO I18n
	"crm.label.picklist.none": "\ud574\ub2f9 \uc5c6\uc74c", //NO I18n
	"crm.usrpop.non.selected" : "\uc120\ud0dd\ub41c \uc0ac\uc6a9\uc790",//NO I18n
	"crm.zti.label.user": "\uc0ac\uc6a9\uc790 \uc774\ub984", //NO I18n
	"crm.label.notSelected" : "\uc120\ud0dd \uc548 \ud568",//NO I18n
	"AM" : "\uc624\uc804",//NO I18n
	"Call" : "\ud1b5\ud654",//NO I18n
	"crm.phoneNo.Link.Title" : "Skype\ub85c \ud1b5\ud654",//NO I18n
	"crm.no.data.found" : "\ub370\uc774\ud130\uac00 \uc5c6\uc2b5\ub2c8\ub2e4.",//NO I18n
	"crm.label.no.options.found" : "\uc635\uc158\uc774 \uc5c6\uc2b5\ub2c8\ub2e4.",//No I18n
	"crm.globalsearch.search.title" : "\uac80\uc0c9",//No I18n
	"None" : "\ud574\ub2f9 \uc5c6\uc74c",//No I18n
	"crm.label.criteria.pattern" : "\uae30\uc900 \ud328\ud134",//No I18n
	"crm.label.edit.criteria.pattern" : "\ud328\ud134 \ud3b8\uc9d1",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\ud328\ud134 \uad04\ud638\uac00 \uc77c\uce58\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\uc870\uac74 \uc5f0\uc0b0\uc790\ub97c \ub2f4\uc740 \uad04\ud638\uac00 \uc720\ud6a8\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",//No I18n
	"crm.criteria.number.notmatch.check" : "{0}\uc5d0\uc11c \ud328\ud134\uc744 \ud655\uc778\ud558\uc2ed\uc2dc\uc624.",//No I18n
	"criteria.error.alert.other.params" : "\uc774 \ud328\ud134\uc758 \ub0b4\uc6a9\uc774 \uc720\ud6a8\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.", //No I18n
	"crm.label.search.for.users": "\uc0ac\uc6a9\uc790 \uac80\uc0c9", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\uae30\uc900 \ud328\ud134\uc774 \uc120\ud0dd\ud55c \uc870\uac74\uc5d0 \ubd80\ud569\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\uae30\uc900 \ud328\ud134\uc774 \uc120\ud0dd\ud55c \uc870\uac74\uc5d0 \ubd80\ud569\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.", //No I18n
	"and" : "\ubc0f", //No I18n
	"or" : "\ub610\ub294", //No I18n
	"crm.label.or" : "\ub610\ub294", //No I18n
	"crm.label.and" : "\uadf8\ub9ac\uace0", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "{0} \ud589\uc5d0 \uc62c\ubc14\ub978 \ud544\ub4dc \ub808\uc774\ube14\uc744 \uc785\ub825\ud558\uc2ed\uc2dc\uc624.", //No I18n
	"crm.criteria.condition.valid.check" : "{0}\uc5d0 \ub300\ud55c \uc62c\ubc14\ub978 \uc870\uac74\uc744 \uc9c0\uc815\ud558\uc2ed\uc2dc\uc624.", //No I18n
	"crm.field.valid.check" : "\uc815\ud655\ud55c {0}\uc744(\ub97c) \uc785\ub825\ud558\uc2ed\uc2dc\uc624.", //No I18n
	"crm.custom.field.less.than.to" : "<i>\uc2dc\uc791</i> \ubc94\uc704\ub294 <i>\uc885\ub8cc</i> \ubc94\uc704\ubcf4\ub2e4 \ud074 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.", //No I18n
	"crm.alert.label.savepattern" : "\uae30\uc900\uc744 \ubcc0\uacbd\ud558\uae30 \uc804\uc5d0 \ud328\ud134\uc744 \uc800\uc7a5\ud558\uc2ed\uc2dc\uc624.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\ub354 \uc774\uc0c1 \uae30\uc900\uc744 \ucd94\uac00\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//No I18n
	"is" : "\ud574\ub2f9",//No I18n
	"isn\'t" : "\uc544\ub2d8",//No I18n
	"contains" : "\ud14d\uc2a4\ud2b8\ub97c \ud3ec\ud568",//No I18n
	"doesn\'t contain" : "\ud3ec\ud568\ud558\uc9c0 \uc54a\uc74c",//No I18n
	"starts with" : "\ud14d\uc2a4\ud2b8\ub85c \uc2dc\uc791",//No I18n
	"ends with" : "\ud14d\uc2a4\ud2b8\ub85c \ub05d\ub0a8",//No I18n
	"is empty" : "\ube44\uc5b4 \uc788\uc74c",//No I18n
	"is not empty" : "\ube44\uc5b4 \uc788\uc9c0 \uc54a\uc74c",//No I18n
	"is before" : "\uc774\uc804",//No I18n
	"is after" : "\uc774\ud6c4",//No I18n
	"between" : "-",//No I18n
	"not between" : "\ub2e4\uc74c \ubc94\uc704\uc5d0 \uc5c6\uc74c",//No I18n
	"Today" : "\uc624\ub298",//No I18n
	"Tommorow" : "\ub0b4\uc77c",//No I18n
	"Tommorow Onwards" : "\ub0b4\uc77c \uc2dc\uc791",//No I18n
	"Yesterday" : "\uc5b4\uc81c",//No I18n
	"Till Yesterday" : "\uc5b4\uc81c\uae4c\uc9c0",//No I18n
	"Last Month" : "\uc9c0\ub09c \ub2ec",//No I18n
	"Current Month" : "\ud604\uc7ac \ub2ec", //No I18n
	"Next Month" : "\ub2e4\uc74c \ub2ec", //No I18n
	"Last Week" : "\uc9c0\ub09c \uc8fc", //No I18n
	"Current Week" : "\uc774\ubc88 \uc8fc", //No I18n
	"Next Week" : "\ub2e4\uc74c \uc8fc", //No I18n
	"Age in Days" : "\uacbd\uacfc \uc77c\uc218", //No I18n
	"Due in Days" : "\uae30\ud55c(\uc77c)", //No I18n
	"Scheduled" : "\uc608\uc57d\ub428", //No I18n
	"Attended Dialled" : "\uc218\uc2e0 \ud1b5\ud654", //No I18n
	"Unattended Dialled" : "\ubd88\ucc38 \ud655\uc778 \ud1b5\ud654 @@", //No I18n
	"Overdue" : "\uc9c0\uc5f0", //No I18n
	"Cancelled" : "\ucde8\uc18c\ub428", //No I18n
	"Received" : "\ubc1b\uc74c", //No I18n
	"Missed" : "\ub193\uce68", //No I18n
	"crm.alert.character.not.allowed" : "{0}\uc740(\ub294) \ud5c8\uc6a9\ub418\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.", //No I18n
	"crm.condition.in.last" : "\ub9c8\uc9c0\ub9c9", //No I18n
	"crm.zinvoice.dueIn" : "\uae30\ud55c", //No I18n
	"on" : "\ucf1c\uc9c4 \uc0c1\ud0dc",//No I18n
	"before" : "\uc774\uc804",//No I18n
	"crm.label.general.small.after" : "\uc774\ud6c4",//No I18n
	"crm.thisweek" : "\uc774\ubc88 \uc8fc",//No I18n
	"crm.label.this.month" : "\ub2f9\uc6d4",//No I18n
	"crm.thisyear" : "\uc62c\ud574",//No I18n
	"crm.source.user.and.system" : "\uc0ac\uc6a9\uc790 \ubc0f \uc2dc\uc2a4\ud15c",//No I18n
	"crm.source.user.or.system" : "\uc0ac\uc6a9\uc790 \ub610\ub294 \uc2dc\uc2a4\ud15c",//No I18n
	"crm.label.system2" : "\uc2dc\uc2a4\ud15c",//No I18n
	"crm.source.user.only" : "\uc0ac\uc6a9\uc790\uc5d0 \uc758\ud574\uc11c\ub9cc",//No I18n
	"crm.source.system.only" : "\uc2dc\uc2a4\ud15c\uc5d0 \uc758\ud574\uc11c\ub9cc",//No I18n
	"crm.condition.till.today" : "\uc624\ub298\uae4c\uc9c0",//No I18n
	"game.month.left" : "1\uac1c\uc6d4",//No I18n
	"game.months.left" : "{0}\uac1c\uc6d4",//No I18n
	"crm.condition.last.30.days" : "\uc9c0\ub09c 30\uc77c \ub0b4",//No I18n
	"crm.condition.last.60.days" : "\uc9c0\ub09c 60\uc77c \ub0b4",//No I18n
	"crm.condition.last.90.days" : "\uc9c0\ub09c 90\uc77c \ub0b4",//No I18n
	"crm.label.filter.typehere" : "\uc5ec\uae30 \uc785\ub825", //No I18N
	"crm.filter.is.not" : "\uc544\ub2d8", //No I18n
	"crm.condition.until.now" : "\uc9c0\uae08\uae4c\uc9c0",//No I18n
	"crm.filter.email.isblocked" : "\ucc28\ub2e8\ub428",//No I18n
	"crm.filter.email.isnotblocked" : "\ucc28\ub2e8 \uc548 \ub428",//No I18n
	"crm.label.no.results.match" : "\uc77c\uce58\ud558\ub294 \uacb0\uacfc \uc5c6\uc74c",//No I18n
	"crm.label.select.user" : "\uc0ac\uc6a9\uc790\ub97c \uc120\ud0dd\ud558\ub824\uba74 \ud074\ub9ad\ud558\uc2ed\uc2dc\uc624.", //No I18n
	"current.logged.in.user": "\ub85c\uadf8\uc778\ud55c \uc0ac\uc6a9\uc790", //NO I18n
	"current.logged.in.user.definition": "\ub808\ucf54\ub4dc \uc791\uc5c5\uc744 \uc2dc\uc791\ud55c \uc0ac\uc6a9\uc790.", //NO i18n
	"crm.security.group": "\uadf8\ub8f9", //NO I18n
	"crm.security.role": "\uc5ed\ud560", //NO I18n
	"Date" : "\ub0a0\uc9dc",//No I18n
	"crm.field.valid.decimal.check2" : "<i>{0}</i> \ud544\ub4dc\ub294 \uc18c\uc218\uc810 \uc774\ud558 {1}\uc790\ub9ac \ubbf8\ub9cc\uc774\uc5b4\uc57c \ud569\ub2c8\ub2e4.",//No I18n
	"crm.field.empty.check" : "{0}\uc740(\ub294) \ube48\uce78\uc77c \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//No I18n
	"crm.label.add.note": "\uba54\ubaa8 \ucd94\uac00", //NO I18n
	"crm.label.simply.by": "\ub9cc\ub4e0 \uc0ac\ub78c", //NO I18n
	"crm.general.addnote": "\uba54\ubaa8 \ucd94\uac00", //NO I18n
	"crm.general.addtitle": "\uc81c\ubaa9 \ucd94\uac00", //NO I18n
	"crm.label.attach.file": "\ud30c\uc77c \ucca8\ubd80", //NO I18N
	"crm.button.cancel": "\ucde8\uc18c", //NO I18N
	"crm.button.save": "\uc800\uc7a5", //NO I18N
	"crm.button.mass.delete": "\uc0ad\uc81c", //NO I18N
	"crm.warning.delete.record": "\"{0}\"\uc744(\ub97c) \ud734\uc9c0\ud1b5\uc5d0 \ubc84\ub9ac\uc2dc\uaca0\uc2b5\ub2c8\uae4c?", //NO I18N
	"crm.label.yes": "\uc608", //NO I18N
	"crm.note.view.previous": "\uc774\uc804 \uba54\ubaa8 \ubcf4\uae30", //NO I18N
  "of": "\uc758", //NO I18N
	"crm.label.notes": "\ucc38\uace0", //NO I18N
	"crm.note.recent.first": "\ucd5c\uadfc \uc0c1\uc704", //NO I18N
	"crm.note.recent.last": "\ucd5c\uadfc \ud558\uc704", //NO I18N
	"crm.territory.label.only": "{0} \ub9cc", //no i18n
	"crm.select" : "\uc120\ud0dd",//No I18n
	"crm.button.apply.filter" : "\ud544\ud130 \uc801\uc6a9",//No I18n
	"PM" : "\uc624\ud6c4",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "\uc911\ubcf5 \uac12\uc740 \ud5c8\uc6a9\ub418\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.duplicate.value.available": "\ub3d9\uc77c\ud55c {1}\uc744(\ub97c) \uac16\ub294 \uae30\uc874 {0}\uc774(\uac00) \uc788\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.duplicate.value.available.multiple": "\ub3d9\uc77c\ud55c {1}\uc744(\ub97c) \uac16\ub294 \uc5ec\ub7ec {0}\uc774(\uac00) \uc788\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.custombutton.nobuttons.found": "\ubc84\ud2bc \uc5c6\uc74c",//no i18n
	"crm.custombutton.create.newbutton": "\ub9cc\ub4e4\uae30 \ubc84\ud2bc",//no i18n
	"crm.custombutton.manage.button": "\ubc84\ud2bc \uad00\ub9ac",//no i18n
	"crm.custombutton.name": "\uc774\ub984",//no i18n
	"crm.customize.custombutton.function.desc": "\uc124\uba85",//no i18n
	"crm.custombutton.action": "\ubc84\ud2bc \uc791\uc5c5",//no i18n
	"crm.custombutton.empty.field.values": "\ub2e4\uc74c \ud544\ub4dc\uc758 \uac12\uc774 \ube44\uc5c8\uc74c:",//no i18n
	"crm.custombutton.proceed.action": "\uacc4\uc18d\ud558\uc2dc\uaca0\uc2b5\ub2c8\uae4c?",//no i18n
	"zb.common.Cancel": "\ucde8\uc18c",//no i18n
	"crm.yes.proceed": "\uc608, \uacc4\uc18d\ud569\ub2c8\ub2e4",//no i18n
	"crm.label.module.merge": "{0} \ubcd1\ud569",//no i18n
	"crm.view.record": "{0} \ubcf4\uae30",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "\ud30c\uc77c \uc120\ud0dd",//no i18n
	"crm.label.subform.addrows": "\ud589 \ucd94\uac00",//no i18n
	"crm.button.save&new": "\uc800\uc7a5 \ubc0f \uc0c8 \ud56d\ubaa9",//no i18n
	//end-cx create form keys
	"Jan" : "1\uc6d4",//No I18n
	"Feb" : "2\uc6d4",//No I18n
	"Mar" : "3\uc6d4",//No I18n
	"Apr" : "4\uc6d4",//No I18n
	"Jun" : "6\uc6d4",//No I18n
	"Jul" : "7\uc6d4",//No I18n
	"Aug" : "8\uc6d4",//No I18n
	"Sep" : "9\uc6d4",//No I18n
	"Oct" : "10\uc6d4",//No I18n
	"Nov" : "11\uc6d4",//No I18n
	"Dec" : "12\uc6d4",//No I18n
	"crm.mb.newversion.msg4" : "\uc54c\uaca0\uc2b5\ub2c8\ub2e4.",//No I18n
	"crm.label.More" :"\ucd94\uac00", //no i18n

	"crm.label.unmapped.stages":"\uacc4\uc815 \uc5c6\uc74c", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\uc774\uc0c1 \ud45c\uc2dc",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\ubbf8\ub9cc \ud45c\uc2dc",//No I18n

	 	 //filter related keys-start
	"crm.lead.prediction.convert.high":"\ub192\uc74c",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"\uc911\uac04",//no i18n
"crm.lead.prediction.convert.low":"\ub0ae\uc74c",//no i18n
"crm.lead.prediction.tooltip.convert":"\uac00\ub2a5\uc131",//no i18n
"crm.lead.prediction.tooltip.score":"\uc810\uc218",//no i18n
"Planned":"\uacc4\ud68d\ub428",//no i18n
"Invited":"\ucd08\ub300\ub428",//no i18n
"Sent":"\ubcf4\ub0c4",//no i18n
"Received":"\ubc1b\uc74c",//no i18n
"Opened":"\uc624\ud508\ub428",//no i18n
"Responded":"\uc751\ub2f5\ub428",//no i18n
"Bounced":"\ubc14\uc6b4\uc2a4\ub428",//no i18n
"Opted\ Out":"\uc218\uc2e0 \uc548 \ud568",//no i18n
"crm.filter.label.with.open":"\uc9c4\ud589 \uc911\uc778 {0} \uc788\uc74c",//no i18n
"crm.filter.label.without.open":"\uc9c4\ud589 \uc911\uc778 {0} \uc5c6\uc774",//no i18n
"crm.filter.label.without.any":"{0} \uc5c6\uc774",//no i18n
"crm.filter.label.with.module":"{0} \uc788\uc74c",//no i18n
"crm.filter.label.activity.due":"{0} \uae30\ud55c",//no i18n
"crm.filter.label.activity.done":"{0} \uc644\ub8cc",//no i18n
"Notes":"\ucc38\uace0",//no i18n
"crm.filter.label.notes.added":"\uba54\ubaa8 \ucd94\uac00\ub428",//no i18n
"crm.label.filter.email.status":"\ucd5c\uc2e0 \uc774\uba54\uc77c \uc0c1\ud0dc",//no i18n
"crm.label.filter.email.clicked":"\ud074\ub9ad\ud568",//no i18n
"crm.label.filter.email.responded":"\uc751\ub2f5\ud568", //no i18n
"crm.label.filter.email.info":"\ub0b4\uac00 \ubcf4\ub0b8/\ubc1b\uc740 \uc774\uba54\uc77c\uc758 \ucd5c\uadfc \uc0c1\ud0dc\ub97c \uae30\uc900\uc73c\ub85c \ub808\ucf54\ub4dc\ub97c \ud544\ud130\ub9c1\ud569\ub2c8\ub2e4.",//no i18n
"crm.filter.label.sent":"\ubcf4\ub0c4",//no i18n
"crm.filter.label.not.sent":"\ubcf4\ub0b4\uc9c0 \uc54a\uc74c",//no i18n
"crm.filter.label.opened":"\uc5f4\uc5b4\ubd04",//no i18n
"crm.filter.label.not.opened":"\uc5f4\uc9c0 \uc54a\uc74c",//no i18n
"crm.filter.label.received":"\ubc1b\uc74c",//no i18n
"crm.filter.label.not.received":"\ubc1b\uc9c0 \uc54a\uc74c",//no i18n
"crm.filter.label.bounced":"\ubc14\uc6b4\uc2a4\ub428",//no i18n
"crm.filter.label.opened.not.replied":"\uc5f4\uc5b4 \ubcf4\uace0 \ub2f5\uc7a5 \uc548 \ud568", //no i18n
"crm.filter.label.any":"\uc704 \ud56d\ubaa9 \uc911 \ud558\ub098",//no i18n
"crm.zia.config.potential.amount":"{0} \uae08\uc561",//no i18n
"Quote\ Stage":"{0} \ub2e8\uacc4",//no i18n
"crm.module.owner":"{0} \uc18c\uc720\uc790",//no i18n
"Potential\ Closing\ Date":"{0} \uc885\ub8cc\uc77c",//no i18n
"crm.lead.prediction.likely.convert":"\uc804\ud658 \uac00\ub2a5\uc131",//no i18n
"crm.predictions.feature.label":"\uc608\uce21",//no i18n
"crm.intelligence.prediction.likelywin":"\uc131\uacf5 \uc608\uc0c1",//no i18n
"crm.intelligence.prediction.likelylose":"\uc2e4\ud328\ub85c \uc608\uc0c1",//no i18n
"crm.intelligence.prediction.halfchance":"\ubc18\ubc18 \ud655\ub960",//no i18n
"crm.intelligence.prediction.score":"\uc608\uce21 \uc810\uc218",//no i18n
"crm.lead.prediction.recent.score":"\ucd5c\uadfc \uc608\uce21 \uc810\uc218",//no i18n
"crm.intelligence.prediction.lastconv":"\uc9c0\ub09c 3\uac1c \ub300\ud654",//no i18n
"crm.intelligence.prediction.recordsfocus":"\ucd08\uc810\uc744 \ub9de\ucd9c \uae30\ub85d",//no i18n
"crm.intelligence.prediction.slowmoving":"\ub290\ub9ac\uac8c \uc9c4\ud589",//no i18n
"crm.intelligence.prediction.trend.down":"\ucd5c\uadfc \ucd94\uc138 \ud558\ud5a5",//no i18n
"crm.label.touched.records":"\ubcc0\uacbd\ub41c \ub808\ucf54\ub4dc",//no i18n
"crm.label.untouched.records":"\ubcc0\uacbd\ud558\uc9c0 \uc54a\uc740 \ub808\ucf54\ub4dc",//no i18n
"crm.label.record.action":"\ub808\ucf54\ub4dc \uc791\uc5c5",//no i18n
"workflow.rule.view.label.Modified":"\uc218\uc815\ub428",//no i18n
"crm.label.not.modified":"\uc218\uc815 \uc548 \ub428",//no i18n
"crm.label.related.records.action":"\uad00\ub828 \ub808\ucf54\ub4dc \uc791\uc5c5",//no i18n
"Done":"\uc644\ub8cc",//no i18n
"crm.label.not.done":"\uc644\ub8cc \uc548 \ub428",//no i18n
"sentiment.model":"\uc774\uba54\uc77c \uc815\ub9ac",//no i18n
"sentiment.criteria.count":"\uac1c\uc218",//no i18n
"sentiment.criteria.percentage":"\ubc31\ubd84\uc728",//no i18n
"sentiment.criteria.lastmail":"\ub9c8\uc9c0\ub9c9 \uc774\uba54\uc77c \ub300\uc0c1",//no i18n
"Chats":"\ucc44\ud305",//no i18n
"Attended":"\ucc38\uc11d\ud568",//no i18n
"crm.lead.prediction.popup.text":"\uc804\ud658 \uac00\ub2a5\uc131\uc774 {0}\uc774\uba74 \uc608\uce21 \uc810\uc218 \ubc94\uc704\uac00 {1}\uc774\uc5b4\uc57c \ud569\ub2c8\ub2e4.",//no i18n
"crm.lead.prediction.popup.final":"\ud544\ud130\ub97c \uc801\uc808\ud558\uac8c \ubcc0\uacbd\ud55c \ud6c4 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",//no i18n
"crm.custom.field.less.than.to1":"\uc2dc\uc791 \ubc94\uc704\ub294 \uc885\ub8cc \ubc94\uc704\ubcf4\ub2e4 \ud074 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
"Last\ Activity\ Date":"\ub9c8\uc9c0\ub9c9 \ud65c\ub3d9 \ub0a0\uc9dc",//no i18n
"crm.label.vendor.name":"{0} \uc774\ub984",//no i18n
"hours":"\uc2dc\uac04",//no i18n
"days":"\uc77c",//no i18n
"weeks":"\uc8fc",//no i18n
"months":"\uac1c\uc6d4",//no i18n
"years":"\ub144",//no i18n
"crm.label.general.small.after":"\uc774\ud6c4",//no i18n
"Last\ Week":"\uc9c0\ub09c \uc8fc",//no i18n
"Last\ Month":"\uc9c0\ub09c \ub2ec",//no i18n
"crm.module.name":"{0} \uc774\ub984",//no i18n
"Campaign":"\ucea0\ud398\uc778",//no i18n
"Tasks":"\uc791\uc5c5",//no i18n
"Calls":"\ud1b5\ud654",//no i18n
"Events":"\uc774\ubca4\ud2b8",//no i18n
"sentiment.criteria.wrongcriteria":"\uae30\uc900\uac12\uc774 {0}\ubcf4\ub2e4 \ud074 \uc218 \uc5c6\uc74c",//no i18n
"crm.chosen.minimum.input.text":"{0}\uc790 \uc774\uc0c1\uc744 \uc785\ub825\ud558\uc2ed\uc2dc\uc624.",//no i18n
"crm.intelligence.prediction.trendup":"\ucd94\uc138 \uc0c1\uc2b9 \uc911",//no i18n
"crm.intelligence.prediction.trenddown":"\ucd94\uc138 \ud558\ud5a5 \uc911",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"\uc644\ub8cc" ,//no i18n
"crm.label.success":"\uc131\uacf5" ,//no i18n
"crm.label.Failure":"\uc2e4\ud328" ,//no i18n
"Both":"\ubaa8\ub450" ,//no i18n
"crm.condition.cannot.empty":"\uc870\uac74\uc740 \ube48\uce78\uc77c \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
"crm.condition.last.30.days":"\uc9c0\ub09c 30\uc77c \ub0b4",//no i18n
"crm.condition.last.60.days":"\uc9c0\ub09c 60\uc77c \ub0b4",//no i18n
"crm.condition.last.90.days":"\uc9c0\ub09c 90\uc77c \ub0b4",//no i18n
"crm.sentiment.Positive":"\ucc2c\uc131",//no i18n
"crm.sentiment.Negative":"\ubc18\ub300",//no i18n
"sentiment.positiveandnegative":"\uc591\uc218 \ubc0f \uc74c\uc218",//no i18n
"sentiment.positiveornegative":"\uc591\uc218 \ub610\ub294 \uc74c\uc218",//no i18n
"sentiment.positiveonly":"\uc591\uc218\ub9cc",//no i18n
"sentiment.negativeonly":"\uc74c\uc218\ub9cc",//no i18n
"crm.sentiment.Neutral":"\uc911\ub9bd",//no i18n
"crm.filters.select.campaign.type":"{0} \uc720\ud615 \uc120\ud0dd",//no i18n
"crm.filters.select.campaign.status":"{0} \uc0c1\ud0dc \uc120\ud0dd",//no i18n
"campaign.Member" : "\uad6c\uc131\uc6d0",//no i18n
	"Service":"\uc11c\ube44\uc2a4",//no i18n
"Activities":"\uc791\uc5c5",//no i18n
"crm.livedesk.pot.nextdays":"\ucc28\ud6c4 {0}\uc77c",//no i18n
"Today\ +\ Overdue":"\uc624\ub298 + \uc9c0\uc5f0",//no i18n
"crm.source.user.and.system":"\uc0ac\uc6a9\uc790 \ubc0f \uc2dc\uc2a4\ud15c",//no i18n
"crm.source.user.or.system":"\uc0ac\uc6a9\uc790 \ub610\ub294 \uc2dc\uc2a4\ud15c",//no i18n
"User":"\uc0ac\uc6a9\uc790",//no i18n
"crm.source.user.only":"\uc0ac\uc6a9\uc790\uc5d0 \uc758\ud574\uc11c\ub9cc",//no i18n
"crm.source.system.only":"\uc2dc\uc2a4\ud15c\uc5d0 \uc758\ud574\uc11c\ub9cc",//no i18n
"Scheduled":"\uc608\uc57d\ub428",//no i18n
"Attended\ Dialled":"\uc218\uc2e0 \ud1b5\ud654",//no i18n
"Unattended\ Dialled":"\ubd88\ucc38 \ud655\uc778 \ud1b5\ud654 @@",//no i18n
"Cancelled":"\ucde8\uc18c\ub428",//no i18n
"crm.filter.email.isblocked":"\ucc28\ub2e8\ub428",//no i18n
"crm.filter.email.isnotblocked":"\ucc28\ub2e8 \uc548 \ub428",//no i18n
"condition.till.now":"\uc9c0\uae08\uae4c\uc9c0",//no i18n
"crm.recurring.no.months":"{0}\uac1c\uc6d4",//no i18n
"crm.lead.prediction.tooltip":"\uc804\ud658 \uac00\ub2a5\uc131 - \uc810\uc218 \ubc94\uc704",//no i18n
"crm.website.activity":"\uc6f9 \uc0ac\uc774\ud2b8 \ud65c\ub3d9",//no i18n
"crm.label.By":"-",//no i18n
"crm.chosen.searching.text":"\uac80\uc0c9 \uc911...",//no i18n
"crm.label.memberstatus.is":"\ubc0f \uad6c\uc131\uc6d0 \uc0c1\ud0dc:",//no i18n
"crm.events.duration":"\uc9c0\uc18d \uc2dc\uac04",//no i18n
"crm.title.clear.name":"\uc9c0\uc6b0\uae30",//no i18n
"crm.label.status.is":"\uc0c1\ud0dc:",//no i18n
"zia.last3.help":"\ub300\ud654\uc5d0\ub294 \ud1b5\ud654, \uc791\uc5c5, {0}, \ubc1b\uc740 \uc774\uba54\uc77c, \uba54\ubaa8, \ubc29\ubb38, \uc18c\uc15c \ub313\uae00, Desk\uc758 \uc9c0\uc6d0 \uc694\uccad\uc774 \ud3ec\ud568\ub429\ub2c8\ub2e4.",//no i18n
"crm.label.tag.related.to":"\uad00\ub828 \ub300\uc0c1",//no i18n
"crm.alert.maximum.text.values.contains":"\uc774 \ud544\ub4dc\uc5d0 {0}\uac1c\uac00 \ub118\ub294 \uac12\uc744 \uc785\ub825\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\uc0c8 {0}\uc774(\uac00) \uc0dd\uc131\ub418\uc5c8\uc2b5\ub2c8\ub2e4.",//No I18n
	"crm.krp.no.records.found" : "{0} \uc5c6\uc74c",//No I18n
	"crm.module.new" : "\uc0c8 {0}",//No I18n
	"crm.label.view" : "\ubcf4\uae30",//No I18n
	"crm.nsocial.customers" : "\uace0\uac1d",//No I18n
	"crm.nsocial.open.potential" : "\uc9c4\ud589 \uc911\uc778 {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\uae30\ud0c0",//No i18n
	"crm.field.length.check" : "{0} \uac12\uc774 \ucd5c\ub300 \uae38\uc774\ub97c \ucd08\uacfc\ud569\ub2c8\ub2e4.", //No I18n
	"crm.lower.now":"\uc9c0\uae08",//no i18n
	"crm.time.min.ago":"{0}\ubd84 \uc804",//no i18n
	"crm.time.mins.ago":"{0}\ubd84 \uc804",//no i18n
	"crm.time.hr.ago":"{0}\uc2dc\uac04 \uc804",//no i18n
	"crm.time.hrs.ago":"{0}\uc2dc\uac04 \uc804", //no i18n
	"AllUsers": "\ubaa8\ub4e0 \uc0ac\uc6a9\uc790", //no i18n
	"crm.label.search":"\uac80\uc0c9",//no i18n
	"crm.api.filterby":"\ud544\ud130\ub9c1 \uae30\uc900",//no i18n
	"crm.customview.nofields.found":"-\uc77c\uce58\ud558\ub294 \ud544\ub4dc \uc5c6\uc74c-",//no i18n
	"crm.setup.system.ziarecommendation":"\ucd94\ucc9c",//no i18n
	"crm.filter.label.all.products":"\ubaa8\ub4e0 {0}",//no i18n
	"crm.filter.label.select.products":"\uc120\ud0dd\ud55c {0}",//no i18n
	"crm.reviewprocess.smart.filter":"\ud504\ub85c\uc138\uc2a4 \ub808\ucf54\ub4dc \uc0c1\ud0dc \uac80\ud1a0",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\uac12\uc744 \uc120\ud0dd\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"crm.segmentation.segment.score":"\uc138\uadf8\uba3c\ud2b8 \uc810\uc218",//no i18n
	"crm.filter.label.in":"\ub2e4\uc74c\uc744 \uae30\uc900\uc73c\ub85c \ud568",//no i18n
	"crm.filter.label.and.purchase.in":"\ub610\ud55c \ub2e4\uc74c\uc5d0\uc11c \uad6c\ub9e4\ud560 \uac00\ub2a5\uc131\uc774 \uc788\ub294",//no i18n
	"crm.filter.label.last.purchased":"\uadf8\ub9ac\uace0 \ucd5c\uadfc\uc5d0 \ud68d\ub4dd\ud55c",//no i18n
	"crm.filter.label.a.day":"\uc77c",//no i18n
	"crm.filter.label.a.week":"\uc8fc",//no i18n
	"crm.filter.label.a.month":"\uc6d4",//no i18n
	"crm.cal.custom":"\uc0ac\uc6a9\uc790 \uc9c0\uc815",//no i18n
	"crm.mb.field.common.empt":"\uac12\uc740 \ube48 \uce78\uc73c\ub85c \ub0a8\uaca8\ub458 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.chosen.error.loading.text":"\uc8c4\uc1a1\ud569\ub2c8\ub2e4. \uacb0\uacfc\ub97c \ub85c\ub4dc\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.filter.label.firstbuy":"\ucc98\uc74c",//no i18n
	"crm.filter.label.cwbab":"\uc758\uc874",//no i18n
	"crm.filter.label.fbt":"\ubc88\ub4e4",//no i18n
	"crm.filter.label.rebuy":"\ubc18\ubcf5",//no i18n
	"crm.filter.label.nextbuy":"\uc21c\uc11c",//no i18n
	"crm.mxnlookup.select" : "{0} \ud560\ub2f9",//No I18n
	"crm.lookup.chooserecord":"{0} \uc120\ud0dd",//no i18n
	"crm.record.selected":"\uc120\ud0dd\ub41c {0}",//no i18n
	"crm.module.empty.message" : "{0} \uc5c6\uc74c",//No I18n
	"crm.mxnlookup.selected" : "\ud560\ub2f9\ub41c {0}",//No I18n
	"crm.label.creator.noPermission" : "\uad8c\ud55c \uac70\ubd80\ub428", //No I18n
	"crm.security.error" : "\uc774 \uc791\uc5c5\uc744 \uc218\ud589\ud560 \uc218 \uc788\ub294 \uad8c\ud55c\uc774 \ubd80\uc871\ud569\ub2c8\ub2e4. \ud574\ub2f9 \uad00\ub9ac\uc790\uc5d0\uac8c \ubb38\uc758\ud558\uc2ed\uc2dc\uc624.", //No I18n
	"crm.segmentation.recency" : "\ucd5c\uc2e0", //No I18n
	"crm.segmentation.frequency" : "\ube48\ub3c4", //No I18n
	"crm.segmentation.monetary" : "\uc218\uc775", //No I18n
	"crm.smartfilter.related.module.msg" : "3\uac1c\uac00 \ub118\ub294 \uad00\ub828 \ubaa8\ub4c8\uc744 \uc120\ud0dd\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(\uc608: \uc774\uba54\uc77c, \uc791\uc5c5, \uba54\ubaa8)", //No I18n
	"crm.smartfilter.related.module.msg2" : "\uc9c0\uc18d\uae30\uac04\uc744 \ube44\uc6cc\ub458 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4", //No I18n
	"crm.label.timeZone": "\ud45c\uc900 \uc2dc\uac04\ub300", //NO I18n
	"crm.label.insufficient.privileges": "\uc774 \uc791\uc5c5\uc744 \uc218\ud589\ud558\uae30 \uc704\ud55c \uad8c\ud55c\uc774 \ubd80\uc871\ud569\ub2c8\ub2e4. \uad00\ub9ac\uc790\uc5d0\uac8c \ubb38\uc758\ud558\uc2ed\uc2dc\uc624.", //NO I18n
	"crm.filter.header.secton.system": "\uc2dc\uc2a4\ud15c \uc815\uc758 \ud544\ud130", //NO I18N
	"crm.filter.header.secton.fields": "\ud544\ub4dc\ub85c \ud544\ud130\ub9c1", //NO I18N
	"crm.createfield.calcinfo.new" : "\uc774 \ud544\ub4dc\ub294 \uc785\ub825 \uc218\uc2dd\uc758 \uc5f0\uc0b0\uc790 \uc5ed\ud560\uc744 \uc218\ud589\ud569\ub2c8\ub2e4.</br> <b>\uc608: 20+20</b>\uc758 \uacb0\uacfc\ub294 \uc790\ub3d9\uc73c\ub85c <b>40</b>",//No i18n
	"crm.lable.read.only" : "\uc77d\uae30 \uc804\uc6a9 \ud544\ub4dc",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc",//No i18n
	"crm.column.unsort" : "\uc815\ub82c \uc548 \ud568",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\uace0\uac1d\uc5d0\uac8c \uacf5\uc720", //NO I18N
	"crm.label.edited":"\ud3b8\uc9d1\ub428",//no i18n
	"crm.label.edited.on":"\ud3b8\uc9d1 \ub0a0\uc9dc",//no i18n
	"crm.message.limit.exceed": "{0} \ubb38\uc790 \ub9cc\uc774 {1}\uc5d0 \ub300\ud574 \ud5c8\uc6a9\ub429\ub2c8\ub2e4.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\uace0\uac1d\uacfc \uacf5\uc720\ub428", //NO I18N
	"crm.button.ok" : "\ud655\uc778", //NO I18N
	"crm.role.already.selected" : "\uc774 \uc5ed\ud560\uc740 \uc774\ubbf8 \uc120\ud0dd\ub418\uc5c8\uc2b5\ub2c8\ub2e4", //no i18n
	"crm.user.deleted": "\uc0ac\uc6a9\uc790\uac00 \uc0ad\uc81c\ub418\uc5c8\uc2b5\ub2c8\ub2e4",  //NO I18N
	"crm.account.closed": "\uc774 \uacc4\uc815\uc740 \ud3d0\uc1c4\ub418\uc5c8\uc2b5\ub2c8\ub2e4",  //NO I18N
	"crm.start.chat": "\ucc44\ud305 \uc2dc\uc791",  //NO I18N
	"crm.recipient.invalid.email" : "\uc62c\ubc14\ub974\uc9c0 \uc54a\uc740 \uc774\uba54\uc77c \ubc1c\uacac", //NO I18N
	"crm.recipient.add.recipient" : "\uc218\uc2e0\uc778 \ucd94\uac00", //NO I18N
	"crm.start.call": "\ud1b5\ud654 \uc2dc\uc791",  //NO I18N
	"crm.start.video.call": "\uc601\uc0c1 \ud1b5\ud654 \uc2dc\uc791",  //NO I18N //ignorei18n_start

	"Score":"\uc810\uc218",
	"Positive Score":"\uc810\uc218(+)",
	"Negative Score":"\uc810\uc218(-)",
	"Touch Point Score":"\ud130\uce58 \ud3ec\uc778\ud2b8 \uc810\uc218",
	"Positive Touch Point Score":"\ud130\uce58 \ud3ec\uc778\ud2b8 \uc810\uc218(+)",
	"Negative Touch Point Score":"\ud130\uce58 \ud3ec\uc778\ud2b8 \uc810\uc218(-)",
	"crm.label.scoring.rules":"\uc810\uc218 \uc9c0\uc815 \uaddc\uce59",
	"crm.label.type.minutes": "ubd84 \ub2e8\uc704\ub85c \uc5ec\uae30\uc5d0 \uc785\ub825", //NO I18N

	"is\ OPEN":"\uc9c4\ud589 \uc911",//no i18n
	"is\ WON":"\uc131\uacf5",//no i18n
	"is\ LOST":"\uc2e4\ud328",//no i18n
	"crm.potential.all.open":"\uc9c4\ud589 \uc911\uc778 \ubaa8\ub4e0 \ub2e8\uacc4",//no i18n
	"crm.potential.all.won":"\uc131\uacf5\uc73c\ub85c \uc885\ub8cc\ub41c \ubaa8\ub4e0 \ub2e8\uacc4",//no i18n

	"crm.potential.all.lost":"\uc2e4\ud328\ub85c \uc885\ub8cc\ub41c \ubaa8\ub4e0 \ub2e8\uacc4",//no i18n
	"crm.campaign.member.status" : "\uad6c\uc131\uc6d0 \uc0c1\ud0dc",//no i18n
	"crm.dashboard.select.type" : "{0} \uc120\ud0dd",//no i18n
	"crm.campaign.service.status":"\uc11c\ube44\uc2a4 \uc0c1\ud0dc",//no i18n

	"crm.label.addColumn":"\uc5f4 \ucd94\uac00",//no i18n
	"crm.button.clear.filter":"\ud544\ud130 \ub2eb\uae30",//no i18n
	"crm.button.show.filter":"\ud544\ud130 \ud45c\uc2dc",//no i18n
	"crm.las.error.user.maxlimit":"\ucd5c\ub300 20\uba85\uc758 \uc0ac\uc6a9\uc790\ub97c \uc120\ud0dd\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.las.error.picklist.maxlimit":"\ucd5c\ub300 20\uac1c\uc758 \uc635\uc158\uc744 \uc120\ud0dd\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",//no i18n

	"crm.fileuploader.message.responseerror": "\uc5c5\ub85c\ub4dc\ud558\uc9c0 \ubabb\ud588\uc2b5\ub2c8\ub2e4.", //NO I18N
	"crm.storage.create.error":"\ub370\uc774\ud130 \uc800\uc7a5 \uacf5\uac04 \ucd5c\ub300 \ud55c\ub3c4\uc5d0 \ub3c4\ub2ec\ud558\uc5ec \uc0c8 \ub808\ucf54\ub4dc\ub97c \ub9cc\ub4e4 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.storage.create.error.client":"\uad00\ub9ac\uc790\uac00 \ucd5c\ub300 \uc800\uc7a5 \uacf5\uac04 \ud55c\ub3c4\uc5d0 \ub3c4\ub2ec\ud558\uc5ec \uc0c8 \ub808\ucf54\ub4dc\ub97c \ub9cc\ub4e4 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4. \uc774 \ubb38\uc81c\ub97c \ud574\uacb0\ud558\ub824\uba74 {0}\uc5d0 \ubb38\uc758\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"crm.storage.avail.info":"({1} \uc911 {0} \ub0a8\uc74c)",//no i18n
	"crm.storage.error.key.manage":"\ub0b4 \ub370\uc774\ud130 \uc800\uc7a5 \uacf5\uac04 \uad00\ub9ac",//no i18n
	"Records":"\ub808\ucf54\ub4dc",//no i18n
	"crm.workflow.alert.additional.recipients" : "\ucd94\uac00 \ubc1b\ub294 \uc0ac\ub78c", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\ub2e4\ub978 \uc774\uba54\uc77c \uc8fc\uc18c\uc640 \uad6c\ubd84\ud558\ub824\uba74 \uc27c\ud45c\ub97c \uc0ac\uc6a9\ud569\ub2c8\ub2e4.", //NO I18N
	"crm.related.contact.account" : "{0} - {1} \uad00\ub828",//No I18n
	"crm.allcontact.show" : "\ubaa8\ub4e0 {0}",//No I18n
	"crm.button.mass.show" : "\ud45c\uc2dc",//No I18n
	"crm.msg.custom.view.not.replied" : "\uba54\uc2dc\uc9c0 \ub2f5\uc7a5 \uc548 \ud568", //NO I18N
	"crm.msg.custom.view.replied" : "\uba54\uc2dc\uc9c0 \ub2f5\uc7a5\ud568",//NO I18N
	"crm.workflow.select.recipients" : "\ubc1b\ub294 \uc0ac\ub78c", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\ud558\ub098 \uc774\uc0c1\uc758 \ud504\ub85c\ud544\uc744 \uc120\ud0dd\ud558\uc2ed\uc2dc\uc624.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\uc8c4\uc1a1\ud569\ub2c8\ub2e4. \uae30\ubcf8 \ud504\ub85c\ud544\uc744 \uc81c\uac70\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//NO I18N
	"crm.inv.label.add.emails" : "\uc774\uba54\uc77c \ucd94\uac00" ,//NO I18N
	"crm.prediction.analytics.filter.year":"\uc791\ub144",//no i18n
	"Previous\ FY":"\uc774\uc804 \ud68c\uacc4 \uc5f0\ub3c4",//no i18n
	"Current\ FY":"\ud604\uc7ac \ud68c\uacc4 \uc5f0\ub3c4",//no i18n
	"Next\ FY":"\ub2e4\uc74c \ud68c\uacc4 \uc5f0\ub3c4",//no i18n
	"Current\ FQ":"\ud604\uc7ac \ud68c\uacc4 \ubd84\uae30",//no i18n
	"Next\ FQ":"\ub2e4\uc74c \ud68c\uacc4 \ubd84\uae30",//no i18n
	"Previous\ FQ":"\uc774\uc804 FQ",//no i18n
	"crm.inv.label.add.emails" : "\uc774\uba54\uc77c \ucd94\uac00", //NO I18N
	"crm.picklist.sample.text":"\uc0d8\ud50c \ud14d\uc2a4\ud2b8",//no i18n
	"crm.more.colors":"\ub354 \ub9ce\uc740 \uc0c9\uc0c1",//no i18n
	"crm.button.back.alone":"\ub4a4\ub85c",//no i18n
	"crm.field.label.email":"\uc774\uba54\uc77c",//no i18n
"crm.zia.nba.feature.label":"\ucc28\uc120 \uc870\uce58",//no i18n
"Meeting":"\ud68c\uc758",//no i18n
"Tomorrow":"\ub0b4\uc77c",//no i18n
"crm.gdpr.notavailable.field":"\uc774\uc6a9 \uac00\ub2a5\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4",//no i18n
	"crm.setup.system.ziasimilarity":"\uc720\uc0ac\ub3c4 \ucd94\ucc9c",//no i18n
	"crm.gdpr.notavailable.field":"\uc774\uc6a9 \uac00\ub2a5\ud558\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4",//no i18n
	"crm.label.simply.in":"\ubc94\uc704",//no i18n
	"crm.filter.label.all.products":"\ubaa8\ub4e0 {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "\uc138\uadf8\uba3c\ud2b8", // NO I18N
	"abm.segment.names": "\uc138\uadf8\uba3c\ud2b8 \uc774\ub984", // NO I18N
	"abm.abm.segment.name": "ABM \uc138\uadf8\uba3c\ud2b8 \uc774\ub984", // NO I18N
	"abm.segmentation.techniques": "ABM \uc138\uadf8\uba3c\ud2b8 \uae30\ubc95", // NO I18N
	"crm.column.list.max.group.limit" : "", //no i18n	
	"abm.filters": "ABM \ud544\ud130", // NO I18N
	"abm.filters.info": "\uc774 \ud544\ud130\ub294 {1}\uc5d0 \uc5f0\uacb0\ub41c {0}\uc744(\ub97c) \uae30\uc900\uc73c\ub85c \uc815\ubcf4\ub97c \ud45c\uc2dc\ud569\ub2c8\ub2e4.", // NO I18N
	"abm.rfm.label": "RFM \ub808\uc774\ube14", // NO I18N
	"abm.firmographics.label": "\ud37c\ubaa8\uadf8\ub798\ud53d \ub808\uc774\ube14" ,// NO I18N
	"abm.recommendation.label": "\ucd94\ucc9c \ub808\uc774\ube14", // NO I18N
	"abm.voc.label": "VOC \ub808\uc774\ube14", // NO I18N
	"abm.engagement.label": "\ucc38\uc5ec \ub808\uc774\ube14", // NO I18N
	"abm.overall.score": "\uc804\uccb4 \uc810\uc218", // NO I18N
	"abm.mood.score": "\ubd84\uc704\uae30 \uc810\uc218", // NO I18N
	"abm.value.score": "\uac00\uce58 \uc810\uc218", // NO I18N
	"abm.engagement.score": "\ucc38\uc5ec \uc810\uc218", // NO I18N
	"abm.rfm.score": "RFM \uc810\uc218", // NO I18N
	"crm.customview.pin.column":"\uc5f4 \uace0\uc815",//no i18n
"crm.customview.unpin.column":"\uc5f4 \uace0\uc815 \ud574\uc81c",//no i18n
	"crux.custom.field.greater.than.equalto":"{0}은(는) {1} 이상이어야 합니다.",
	"crux.users.selected.plural" : "사용자가 {0}명 선택되었습니다.",
	"crux.user.selected.singular" :"사용자가 {0}명 선택되었습니다.",
	"crux.criteria.empty.secondaryfield.module" : "{1} 모듈에 일치하는 {0} 필드가 없습니다",
	"crux.criteria.empty.secondaryfield" : "비교할 수 있는 {0} 필드가 없습니다. 비교할 값을 입력하여 주십시오.",
	"crux.logged.in.role.definition" : "레코드 작업을 시작한 사용자 역할",
	"zia.similarity.smartfilter.score":"\uc720\uc0ac\ub3c4 \uc810\uc218",//no i18n
	"zia.similarity.smartfilter.records":"\uc720\uc0ac {0}/",//no i18n
	"zia.similarity.smartfilter.records.search":"\ud45c\uc2dc: \uc720\uc0ac {0}/",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"{0}\uac1c\uac00 \ub118\ub294 {1}\uc744(\ub97c) \uc120\ud0dd\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crux.custom.field.greater.than.equalto":"{0}은(는) {1} 이상이어야 합니다.",
	"crux.users.selected.plural" : "사용자가 {0}명 선택되었습니다.",
	"crux.user.selected.singular" :"사용자가 {0}명 선택되었습니다.",
	"crux.criteria.empty.secondaryfield.module" : "{1} 모듈에 일치하는 {0} 필드가 없습니다",
	"crux.criteria.empty.secondaryfield" : "비교할 수 있는 {0} 필드가 없습니다. 비교할 값을 입력하여 주십시오.",
	"crux.logged.in.role.definition" : "레코드 작업을 시작한 사용자 역할",
	"crux.max.limit.unselect" : "{0} {1}개 이상 선택 해제할 수 없습니다.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d이(가) 이미 선택되었습니다", //NO I18N
	"crm.label.true" : "\ucc38",//NO I18N
	"crm.label.false" : "\uac70\uc9d3",//NO I18N
	"crm.record.locked" : "\uc7a0\uae40",//NO I18N
	"crm.filter.number.range":"{0}-{1}\uc758 \uac12",//no i18n
	"crm.smartfilter.picklist.options.msg":"10\uac1c\uac00 \ub118\ub294 \uc120\ud0dd \uac12\uc5d0 \ub300\ud574 \uc774 \uc635\uc158\uc744 \uc120\ud0dd\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"5\uac1c\uac00 \ub118\ub294 {0}\uc744(\ub97c) \uc120\ud0dd\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"{0}\uac1c\uac00 \ub118\ub294 {1}\uc744(\ub97c) \uc120\ud0dd\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.best.time.column.label":"\uac00\uc7a5 \uc88b\uc740 \uc5f0\ub77d \uc2dc\uac04",//no i18n
	"crm.competitoralert.mentioned.in.email":"<b>\uc774\uba54\uc77c</b>\uc5d0\uc11c \uc5b8\uae09\ub428",//no i18n
	"crm.competitoralert.with.sentiment":"\uc13c\ud2f0\uba58\ud2b8\ub85c",//no i18n
	"crm.competitoralert.name.error":"\uacbd\uc7c1\uc0ac \uc774\ub984\uc5d0 \ub300\ud574 \uc62c\ubc14\ub978 \uac12\uc744 \uc785\ub825\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"crm.competitoralert.date.error":"\uc774\uba54\uc77c\uc5d0\uc11c \uacbd\uc7c1\uc0ac \uba58\uc158\uc5d0 \ub300\ud574 \uc62c\ubc14\ub978 \ub0a0\uc9dc\ub97c \uc785\ub825\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"crm.competitoralert.sentiment.error":"\uacbd\uc7c1\uc0ac \uc13c\ud2f0\uba58\ud2b8\uc5d0 \ub300\ud574 \uc62c\ubc14\ub978 \uc635\uc158\uc744 \uc120\ud0dd\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"crm.competitor.name":"\uacbd\uc7c1\uc0ac",
"crm.lookup.advance.error.msg" : "\ud544\ud130\ub97c \uc801\uc6a9\ud558\ub294 \uc911 \ubb34\uc5b8\uac00 \uc798\ubabb\ub418\uc5c8\uc2b5\ub2c8\ub2e4. \ud398\uc774\uc9c0\ub97c \uc0c8\ub85c \uace0\uce58\uace0 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",
	"Contact\ Name" : "{0} \uc774\ub984",
	"Lead\ Name" : "{0} \uc774\ub984",
	"-None-" : "\uc5c6\uc74c",
	"January" : "1\uc6d4",
	"February":"2\uc6d4",
	"March":"3\uc6d4",
	"April":"4\uc6d4",
	"May":"5\uc6d4",
	"June":"6\uc6d4",
	"July":"7\uc6d4",
	"August":"8\uc6d4",
	"September":"9\uc6d4",
	"October":"10\uc6d4",
	"November":"11\uc6d4",
	"December":"12\uc6d4",
	// Image Upload Open
	"crm.label.browse.files":"\ud30c\uc77c \ucc3e\uc544\ubcf4\uae30",//no i18n
	"crm.label.lowercase.or":"\ub610\ub294",//no i18n
	"crm.image.header":"\uc774\ubbf8\uc9c0",//no i18n
	"crm.image.n.n":"N:N \ube44\uc728",//no i18n
	"crm.image.2.2":"2:2 \ube44\uc728",//no i18n
	"crm.image.4.3":"4:3 \ube44\uc728",//no i18n
	"crm.image.16.9":"16:9 \ube44\uc728",//no i18n
	"crm.image.height.width.swap":"\ub192\uc774 \ubc0f \ub108\ube44 \uad50\uccb4",//no i18n
	"crm.image.rotate.image":"\ud68c\uc804",//no i18n
	"crm.label.lowercase.of":"/",//no i18n
	"crm.image.empty.name.placeholder" : "\uc774\ub984 \uc785\ub825",
	"crm.image.crop.and.rotate" : "\uc790\ub974\uae30 \ubc0f \ud68c\uc804",
	"crm.image.description" : "\uc124\uba85 \ucd94\uac00...",
	"crm.image.actual.size" : "\uc2e4\uc81c \ud06c\uae30",
	"crm.image.reset" : "\uc7ac\uc124\uc815",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\ub2e4\uc6b4\ub85c\ub4dc",//no i18n
	"crm.label.edit":"\ud3b8\uc9d1",//no i18n
	"crm.label.close":"\ub2eb\uae30",//no i18n
	"crm.label.any":"\uc784\uc758",
	"crm.image.description":"\uc124\uba85 \ucd94\uac00...",//no i18n
	"crm.image.zoom.in":"\ud655\ub300",//no i18n
	"crm.image.zoom.out":"\ucd95\uc18c",//no i18n
	"crm.label.lowercase.of":"/",//no i18n
	"crm.image.desc.maxsize":"\uc124\uba85\uc740 255\uc790 \ubbf8\ub9cc\uc774\uc5b4\uc57c \ud569\ub2c8\ub2e4.",//no i18n
	"crm.image.name.maxsize":"\uc774\ub984\uc740 100\uc790 \uc774\ub0b4\uc5ec\uc57c \ud569\ub2c8\ub2e4.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"\ucd1d \ud30c\uc77c \ud06c\uae30\uac00 {0}\uc758 \ud5c8\uc6a9 \ud55c\ub3c4\ub97c \ucd08\uacfc\ud569\ub2c8\ub2e4.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\ucd5c\ub300 {0} \uac1c\uc758 \ud30c\uc77c \ub9cc \uc5c5\ub85c\ub4dc\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\ucd5c\ub300 {0}\uac1c\uc758 \ud30c\uc77c\ub9cc \ucca8\ubd80\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.ImageuploadField.size.limit":"\ucd1d \uc774\ubbf8\uc9c0 \ud06c\uae30\uac00 {0} Mb\uc758 \ud5c8\uc6a9 \ud55c\ub3c4\ub97c \ucd08\uacfc\ud569\ub2c8\ub2e4.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"1\uac1c \uc774\ubbf8\uc9c0\ub9cc \uc120\ud0dd\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4",//no i18n
	"crm.image.crop.error":"\uc774\uc9c0\ub97c \uc790\ub97c \uc218 \uc5c6\uc2b5\ub2c8\ub2e4. \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624!",//no i18n
	"crm.attach.here":"\uc5ec\uae30",//no i18n
	"crm.image.unsupported.corrupted.single":"\uc5c5\ub85c\ub4dc\ud558\ub824\ub294 \uc0ac\uc9c4\uc774 \uc190\uc0c1\ub418\uc5c8\uc2b5\ub2c8\ub2e4.",//no i18n
	"Browse":"\ucc3e\uc544\ubcf4\uae30",//no i18n
	"crm.imageupload.failure":"\uc77c\ubd80 \uc774\ubbf8\uc9c0\ub294 \uc5c5\ub85c\ub4dc\ud560 \uc218 \uc5c6\uc2b5\ub2c8\ub2e4. \ub098\uc911\uc5d0 \ub2e4\uc2dc \uc2dc\ub3c4\ud558\uc2ed\uc2dc\uc624.",//no i18n
	"zc.editor.width":"\ub108\ube44",//no i18n
	"ze.editor.height":"\ub192\uc774",//no i18n
	"crm.label.delete":"\uc0ad\uc81c",//no i18n
	"crm.image.error.gif":"GIF \uc774\ubbf8\uc9c0\ub294 \uc790\ub97c \uc218 \uc5c6\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.fileuploader.message.morefiles":"\ub2e4\ub978 {0}\uac1c \ud30c\uc77c",//no i18n
	"crm.fileuploader.message.invalidfileType1":"{0} \ud30c\uc77c\uc740 \uc9c0\uc6d0\ub418\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"{0} \ubc0f {1} \ud30c\uc77c\uc774 \uc9c0\uc6d0\ub418\uc9c0 \uc54a\uc2b5\ub2c8\ub2e4.",//no i18n
	"crm.attach.upload.sizelimit":"\ucd1d \ud06c\uae30 \ud55c\ub3c4",//no i18n
	
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "\ucd5c\ub300 {0} \uac1c\uc758 \ud30c\uc77c \ub9cc \uc5c5\ub85c\ub4dc\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",
	"crm.file.upload.maxlength.exceeds" : "{0}\uc5d0\uc11c 1\uac1c \ud30c\uc77c\ub9cc \uc5c5\ub85c\ub4dc\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",


	"crm.label.add.tags":"\ud0dc\uadf8 \ucd94\uac00",//no i18n
	"crm.confirm.deassociate":"\ud560\ub2f9 \ud574\uc81c"//no i18n
}
